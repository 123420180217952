// extracted by mini-css-extract-plugin
export var buttonAllExamples = "styles-module--buttonAllExamples--233d9";
export var contentAndVideoLayout = "styles-module--contentAndVideoLayout--40db2";
export var contentContainer = "styles-module--contentContainer--d8c17";
export var contentContainerContent = "styles-module--contentContainerContent--a00a1";
export var demograhicsAndUrl = "styles-module--demograhicsAndUrl--7726d";
export var exampleFooter = "styles-module--exampleFooter--7e4ac";
export var navCompanyLogo = "styles-module--navCompanyLogo--6690c";
export var navCompanyName = "styles-module--navCompanyName--a791f";
export var tags = "styles-module--tags--33c08";
export var task = "styles-module--task--8d4a8";
export var title = "styles-module--title--f7402";
export var topNavigation = "styles-module--topNavigation--dbf48";
export var useThisTemplateDescription = "styles-module--useThisTemplateDescription--1a023";
export var videoContainer = "styles-module--videoContainer--19262";
export var videoPlayer = "styles-module--videoPlayer--6e9f5";
export var websiteUrl = "styles-module--websiteUrl--60638";