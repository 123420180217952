// extracted by mini-css-extract-plugin
export var answer = "taskStep-module--answer--bfb36";
export var content = "taskStep-module--content--22147";
export var header = "taskStep-module--header--c9e69";
export var ratingItem = "taskStep-module--ratingItem--aef76";
export var ratingItem_active = "taskStep-module--ratingItem_active--385a2";
export var ratingItems = "taskStep-module--ratingItems--32e1e";
export var ratingLabel = "taskStep-module--ratingLabel--15300";
export var ratingLabelRight = "taskStep-module--ratingLabelRight--80ab1";
export var ratingLabels = "taskStep-module--ratingLabels--b8ae2";
export var taskStep = "taskStep-module--taskStep--d5a21";